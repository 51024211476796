<template>
  <footer class="footer">
    <div class="footer-content max-container">
      <div class="agent-buttons__container">
        <section class="agent-buttons">
          <a href="/">
            <img class="header-content__img" src="@/assets/images/logo.png" alt="thirddown" />
          </a>
          <a class="primary-button primary-button__classic" :href="agentClassic" target="_blank">agente clasico</a>
          <a class="primary-button primary-button__new" :href="agentNew" target="_blank">agente</a>
        </section>
      </div>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
          <router-link class="footer-content__list--anchore" :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
    </div>
    </footer>
</template>

<script>
  export default {
    name: "FooterComponent",
    setup() {
      const agentClassic = "https://agents.juegayaa.com/";
      const agentNew = "https://adm.juegayaa.com/";
      return {
        agentClassic,
        agentNew,
        routes: [
          {
            route      :  'deporte',
            name        : 'deporte'

          },
          {
            route      :  'casino',
            name        : 'casino'

          },
          {
            route      :  'caballos',
            name        : 'caballos'

          },
          {
            route      :  'apuestas-en-vivo',
            name        : 'apuestas en vivo'

          },
          {
            route      :  'reglas-de-deporte',
            name        : 'reglas de deporte'
          },
          {
            route      :  'reglas-de-la-casa',
            name        : 'reglas de la casa'
          },
          {
            route       :  'reglas-de-caballos',
            name      :  'reglas de caballos'
          },
          {
            route      :  'reglas-parlay-del-mismo-equipo',
            name        : 'reglas parlay del mismo equipo'
          },
        ]
      }
    },
  };
</script>
