<template>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__logo">
        <a href="/">
          <img class="header-content__img" src="@/assets/images/logo.png" alt="juegayaa" />
        </a>
      </div>

      <div class="header-content__cta">
        <div class="header-buttons">
          <button class="btn-login secondary-button secondary-button__classic" type="submit" @click="openModal">Ingresar</button>
        </div>
      </div>
    </div>

    <div>
      <nav class="header-content__nav2">
        <div class="header-nav__container">
          <ul class="header-nav__menu">
            <div class="header-content__info">
              <ul class="header-content__info--list">
                <li class="header-content__info--list-item">
                  <router-link to="deporte">Deporte</router-link>
                </li>
                <li class="header-content__info--list-item">
                  <router-link to="casino">Casino</router-link>
                </li>
                <li class="header-content__info--list-item">
                  <router-link to="caballos">caballos</router-link>
                </li>
                <li class="header-content__info--list-item">
                  <router-link to="apuestas-en-vivo">apuestas en vivo</router-link>
                </li>
                <li class="header-content__info--list-item">
                  <router-link to="crea-tu-apuesta">crea tu apuesta</router-link>
                </li>
              </ul>
            </div>
          </ul>
        </div>
      </nav>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
import ModalComponent from "@/components/modal.vue";

export default {
  name: "HeaderComponent",
  components: {
    ModalComponent,
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
  },
  setup:() => {
      const backendUrl = "juegayaa.com";
      const DGS_SITEID = 1000;
      
      return {
        backendUrl,
        DGS_SITEID
      }
    },
};
</script>
