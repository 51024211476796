<template>
  <div class="modal-backdrop" v-if="showModal">
    <div class="modal-content__heading">
      <h3>Inicio</h3>
      <button class="close-button" @click="closeModal">
        <span class="material-symbols-rounded">close</span>
      </button>
    </div>
    <div class="modal-content">
      <!-- Tab navigation -->
      <div class="tabs">
        <div class="tab" @click="changeTab('betslip')">
          <span class="tab__name" :class="{ 'active-tab': activeTab === 'betslip' }">
            Nueva Versión
          </span>
        </div>
        <div class="tab" @click="changeTab('clasic')">
          <span class="tab__name" :class="{ 'active-tab': activeTab === 'clasic' }" >
            Clásico
          </span>
        </div>
      </div>

      <!-- Contenido de tu modal aquí -->
      <form v-if="activeTab === 'betslip'" class="login__form" name="LoginForm" action="javascript:void(0)" onsubmit="BackEndLogin(this); return false">
        <div class="login__form--item">
          <label class="login__form--label" for="username">Usuario</label>
          <input class="login__form--input" name="username" id="username" type="text" required placeholder="Usuario"/>
        </div>

        <div class="login__form--item">
          <label class="login__form--label" for="password">Contraseña</label>
          <input class="login__form--input" type="password" name="password" id="password" required placeholder="Contraseña"/>
        </div>
        
        <div class="text-center mt-2"> 
          <span name="msj_loading" id="id-login-loading"></span>
          <small class="text-danger" name="msj_error_lg"></small>
        </div>

        
        <div class="login__btns-login">
          <input type="submit" class="login-button" name="send" id="send" value="Iniciar sesión">
          <input type="hidden" name="BackEndUrl" :value="`https://betslip.${backendUrl}`">
        </div>

        <!-- <div class="login__link">
          <router-link to="olvidaste-la-contrasena" @click="closeModal" >¿Olvidaste la contraseña?</router-link>
        </div> -->
      </form>

      <div v-if="activeTab === 'clasic'">
        <form class="login__form form__mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
          <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
          <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
          <label class="login__form--label" for="username">Usuario</label>
          <input class="login__form--input" type="text" placeholder="Usuario" name="account" id="account">
          <label class="login__form--label" for="password">Contraseña</label>
          <input class="login__form--input" type="password" placeholder="Contraseña" name="password" id="password">
          <button class="btn-login login-button btn-login-bottom" type="submit">iniciar sesión</button>
        </form>
        <!-- Desktop form -->
        <form class="login__form form__desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
          <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
          <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
          <label class="login__form--label" for="username">Usuario</label>
          <input class="login__form--input" type="text" placeholder="Usuario" name="account" id="account">
          <label class="login__form--label" for="password">Contraseña</label>
          <input class="login__form--input" type="password" name="password" placeholder="Contraseña" id="password">
          <button class="btn-login login-button btn-login-bottom" type="submit">Iniciar Sesión</button>
        </form>
      </div>

      <div class="modal-content__links">
        <a class="modal-content__links--link" :href="agentClassic" target="_blank">agente clásico</a>
        <a class="modal-content__links--link" :href="agentNew" target="_blank">agente</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalComponent',
    setup:() => {
      const backendUrl = "juegayaa.com";
      const agentClassic = "https://agents.juegayaa.com/";
      const agentNew = "https://adm.juegayaa.com/";
      const DGS_SITEID = 1073;
      
      return {
        backendUrl,
        agentClassic,
        agentNew,
        DGS_SITEID
      }
    },
    data() {
      return {
        showModal: false,
        activeTab: 'betslip',
      }
    },
    methods: {
      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
      changeTab(tab) {
      this.activeTab = tab;
      }
    }
  }
</script>